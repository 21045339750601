import React from "react";
import Fade from "react-reveal/Fade";
import "./headerD.css";

const HangMirror = () => {
  return (
    <div className="plain-main-root">
      <div className="plain-sub-root">
        <div className="plain-text-div">
          <Fade left duration={1500}>
            <h1 className="pain-heading">
              Defy Gravity, Not Your Design Dreams: Trust Our Heavy Mirror
              Hanging Expertise
            </h1>
            <p className="plain-text">
              Have you fallen head over heels for a magnificent, heavy mirror
              but dread the thought of wrestling it onto your wall? Fear not!
              We're here to turn your design aspirations into reality, no matter
              the weight of your chosen masterpiece. Our team of skilled
              professionals specializes in the art of securely and seamlessly
              hanging heavy mirrors, ensuring both your safety and the
              preservation of your walls. We understand that a statement mirror
              deserves a flawless installation, and we have the tools,
              techniques, and experience to achieve just that. Say goodbye to
              worries about crooked hangings, cracked walls, or worse, shattered
              dreams. With our meticulous approach and unwavering commitment to
              excellence, your heavy mirror will become a captivating focal
              point in your space, reflecting your impeccable taste and our
              expert craftsmanship. Let us handle the heavy lifting, so you can
              focus on enjoying the beauty and functionality of your stunning
              new addition.
            </p>
          </Fade>
        </div>
        <div className="img-div-plain">
          <Fade right duration={1500}>
            <img src="/home1/before2.jpg" alt="" className="img-plain" />
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default HangMirror;
