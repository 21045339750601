import React, { useState, useEffect } from "react";
import "./headerD.css";

const AboutHome = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [fading, setFading] = useState(false);

  const images = ["/home1/abu.jpg", "/home1/abu1.jpg"];

  useEffect(() => {
    const interval = setInterval(() => {
      setFading(true);
      setTimeout(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
        setFading(false);
      }, 500);
    }, 3000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="main-about-root">
      <div className="about-sub-root">
        <div className="img-about-div">
          <img
            src={images[currentSlide]}
            alt="about"
            className={fading ? "img-about-f" : "img-about"}
          />
        </div>
        <div className="about-text-div">
          <h2 className="about-text-main-heading">
            The Perfect Bathroom Upgrade
          </h2>
          <p className="about-sub-head">1. Schedule an Appointment</p>
          <p className="about-sub-text">
            Meet with our Design Consultant on-site to explore frame samples,
            measure your mirrors, and receive a personalized quote.
          </p>
          <p className="about-sub-head">
            2. Custom Frame Creation & Installation
          </p>
          <p className="about-sub-text">
            We craft your frames to order and ensure expert installation.
          </p>
          <p className="about-sub-head">
            3. Enjoy Your Beautifully Framed Mirrors
          </p>
          <p className="about-sub-text">
            Transform your bathroom with stunning framed mirrors that enhance
            your space.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutHome;
