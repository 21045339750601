import React from "react";
import "./headerD.css";

function Services() {
  return (
    <section className="tc-services-style1">
      <div className="content section-padding section-padding-x">
        <div className="container">
          <div className="title mb-80 text-center framing-txt">
            <p className="color-666 text-uppercase"> our framing services </p>
          </div>
          <div className="services" style={{ textAlign: "center" }}>
            <div className="row">
              <div className="col-lg-6">
                <a
                  href="#"
                  className="service-card wow fadeInUp"
                  data-wow-delay="0.2s"
                >
                  <h5 className="fsz-24 mb-20"> Before Framing </h5>
                  <div className="img">
                    <img
                      src="/home1/before1.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  {/* <div className="text color-666 mt-50">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </div>
                  <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span> */}
                </a>
              </div>
              <div className="col-lg-6">
                <a
                  href="#"
                  className="service-card wow fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <h5 className="fsz-24 mb-20">After Framing</h5>
                  <div className="img">
                    <img
                      src="/home1/before2.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  {/* <div className="text color-666 mt-50">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </div>
                  <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span> */}
                </a>
              </div>
              {/* <div className="col-lg-3">
                <a
                  href="#"
                  className="service-card wow fadeInUp"
                  data-wow-delay="0.6s"
                >
                  <div className="icon">
                    <i className="la la-bed"></i>
                  </div>
                  <h5 className="fsz-24 mb-20"> Furniture Productions </h5>
                  <div className="img">
                    <img
                      src="/home1/assets/img/services/ser3.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">
                    Bespoke Furniture, Material Supply, Online Store,
                    Distribute, 3D Modeling
                  </div>
                  <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span>
                </a>
              </div>
              <div className="col-lg-3">
                <a
                  href="#"
                  className="service-card mt-150 wow fadeInUp"
                  data-wow-delay="0.8s"
                >
                  <div className="icon">
                    <i className="la la-comments"></i>
                  </div>
                  <h5 className="fsz-24 mb-20">
                    Project Consulting & Supervisor
                  </h5>
                  <div className="img">
                    <img
                      src="/home1/assets/img/services/ser4.jpg"
                      alt=""
                      className="img-cover"
                    />
                  </div>
                  <div className="text color-666 mt-50">
                    Project Analysis, Bid Documentation, Construction Supervisor
                  </div>
                  <span className="arrow">
                    <i className="ti-arrow-top-right"></i>
                  </span>
                </a>
              </div> */}
            </div>
          </div>
          <div className="text-center">
            <a
              href="#"
              className="butn rounded-pill mt-80 hover-bg-black btn-txt text-black"
            >
              <span>
                Schedule your free inhome consultation now!
                <i className="medium ms-1 ti-arrow-top-right"></i>
              </span>
            </a>
          </div>
        </div>
      </div>
      <div className="ser-img">
        <img src="/home1/bigM.jpg" alt="" />
      </div>
    </section>
  );
}

export default Services;
