import React from "react";
import Fade from "react-reveal/Fade";
import "./headerD.css";

const HangFramedArt = () => {
  return (
    <div className="plain-main-root">
      <div className="plain-sub-root-old">
        <div className="img-div-plain">
          <Fade left duration={1500}>
            <img src="/home1/before2.jpg" alt="" className="img-plain" />
          </Fade>
        </div>
        <div className="plain-text-div">
          <Fade right duration={1500}>
            <h1 className="pain-heading">
              Elevate Your Art: Flawless Framed Art & Mirror Hanging
            </h1>
            <p className="plain-text">
              Is your precious framed art or mirror leaning precariously against
              a wall, waiting for that perfect moment to grace your space? Let
              us transform that moment into a masterpiece! We understand that
              hanging framed artwork or mirrors requires more than just a hammer
              and nails; it demands precision, finesse, and a keen eye for
              aesthetics. Our team of dedicated professionals possesses all
              three, ensuring that your cherished pieces are displayed at their
              absolute best. Whether it's a gallery wall of family photos, a
              statement mirror above your mantelpiece, or a prized artwork that
              deserves a place of honor, we have the expertise to handle any
              project with care and precision. Say goodbye to crooked hangings,
              uneven spacing, or nail holes that mar your walls. Our commitment
              to excellence ensures that your framed art and mirrors will not
              only be securely mounted but also perfectly positioned to
              complement your decor and enhance the overall ambiance of your
              space. Let us take the stress out of hanging your cherished
              pieces, so you can focus on enjoying the beauty and artistry they
              bring to your life.
            </p>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default HangFramedArt;
