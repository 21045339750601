import React from "react";
import Fade from "react-reveal/Fade";
import "./headerD.css";

const PlainMirrors = () => {
  return (
    <div className="plain-main-root">
      <div className="plain-sub-root">
        <div className="plain-text-div">
          <Fade left duration={1500}>
            <h1 className="pain-heading">
              Add a Frame to Your Existing Mirror
            </h1>
            <p className="plain-text">
              If you're looking to elevate the style of your bathroom mirror
              without the hassle of removing it from the wall, adding a frame
              directly onto the existing mirror is a fantastic solution. This
              DIY project offers a quick and budget-friendly way to achieve a
              custom-framed look, instantly transforming your bathroom's
              aesthetic. The process is relatively simple: start by carefully
              measuring your mirror's dimensions to ensure a perfect fit for
              your frame. Next, select a frame material that complements your
              bathroom's décor – options like wood, metal, or even decorative
              molding offer versatile choices. Once you've acquired your frame,
              it's time for the installation.
            </p>
          </Fade>
        </div>
        <div className="img-div-plain">
          <Fade right duration={1500}>
            <img src="/home1/before2.jpg" alt="" className="img-plain" />
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default PlainMirrors;
