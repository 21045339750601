import React from "react";
import Fade from "react-reveal/Fade";
import "./headerD.css";

const DontHaveMirror = () => {
  return (
    <div className="plain-main-root">
      <div className="plain-sub-root">
        <div className="plain-text-div">
          <Fade left duration={1500}>
            <h1 className="pain-heading">
              Mirror on the Wall, We'll Bring it Framed and All!
            </h1>
            <p className="plain-text">
              In the quest for a well-appointed living space, a mirror is more
              than just a reflective surface; it's a functional and decorative
              element that can transform a room. But what if you find yourself
              in a space devoid of this essential piece? Fret not, for we have
              the perfect solution. We understand the importance of a mirror,
              not only for its practical use in checking your appearance but
              also for its ability to create an illusion of spaciousness and
              amplify natural light. That's why we're offering a complete
              package – a mirror with a frame, ready to hang and enhance your
              space. No more staring at blank walls or making do with makeshift
              solutions. With our framed mirrors, you'll add instant style and
              functionality to any room, whether it's your bedroom, bathroom,
              living room or entryway. Say goodbye to mirrorless woes and hello
              to a beautifully framed reflection.
            </p>
          </Fade>
        </div>
        <div className="img-div-plain">
          <Fade right duration={1500}>
            <img src="/home1/before2.jpg" alt="" className="img-plain" />
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default DontHaveMirror;
