import React, { useState, useEffect } from "react";
import Fade from "react-reveal/Fade";
import "./headerD.css";

const HeaderD = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [activeSlide, setActiveSlide] = useState(1);
  const [fading, setFading] = useState(false);

  const images = ["/home1/h.jpg", "/home1/h1.jpg"];
  const images2 = ["/home1/a1.jpg", "/home1/a2.jpg"];
  const images3 = ["/home1/h3.jpg", "/home1/h4.jpg"];
  const images4 = ["/home1/abu.jpg", "/home1/abu1.jpg"];

  const handleSlideChange = (num) => {
    setActiveSlide(num);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setFading(true);
      setTimeout(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
        setFading(false);
      }, 500);
    }, 3000);

    return () => clearInterval(interval);
  }, [images.length]);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveSlide((prevSlide) => (prevSlide % 4) + 1);
    }, 90000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      {activeSlide === 1 && (
        <Fade duration={1500}>
          <div className="header-D-sub-root">
            <div className="headerD-text-div">
              <h6 className="main-sub-header">Frame Plain Mirrors</h6>
              <h1 className="hero-head">
                Add a Frame to Your Existing Mirror Without Removing It from the
                Wall
              </h1>
              <p className="hero-txt">
                An easy solution for achieving a custom framed look for your
                bathroom
              </p>
            </div>
            <div className="img-div">
              <img
                src={images[currentSlide]}
                alt="home-hero"
                className={fading ? "fade-out" : "heroImg"}
              />
            </div>
          </div>
        </Fade>
      )}
      {activeSlide === 2 && (
        <Fade duration={1500}>
          <div className="header-D-sub-root-b">
            <div className="headerD-text-div-b">
              <h6 className="main-sub-header">Frame Plain Mirrors</h6>
              <h1 className="hero-head">
                Transform Your Mirror, Transform Your Space
              </h1>
              <p className="hero-txt">
                A great way to update the look of your entire bathoom.
              </p>
            </div>
            <div className="img-div">
              <img
                src={images2[currentSlide]}
                alt="home-hero"
                className={fading ? "fade-out" : "heroImg"}
              />
            </div>
          </div>
        </Fade>
      )}
      {activeSlide === 3 && (
        <Fade duration={1500}>
          <div className="header-D-sub-root">
            <div className="headerD-text-div">
              <h6 className="main-sub-header">Custom Mirrors</h6>
              <h1 className="hero-head">Don’t Have Mirrors? No Problem!</h1>
              <p className="hero-txt">
                We provide custom framed mirrors to suit your needs. Whether
                you’re building a new home or simply lack existing mirrors,
                we’ve got you covered. We will design and craft a custom framed
                mirror specifically for your space and handle the installation
                to ensure it looks perfect.
              </p>
            </div>
            <div className="img-div">
              <img
                src={images3[currentSlide]}
                alt="home-hero"
                className={fading ? "fade-out" : "heroImg"}
              />
            </div>
          </div>
        </Fade>
      )}
      {activeSlide === 4 && (
        <Fade duration={1500}>
          <div className="header-D-sub-root-b">
            <div className="headerD-text-div-b">
              <h6 className="main-sub-header">Artisanal Custom Mirrors</h6>
              <h1 className="hero-head">No Mirrors? No Worries!</h1>
              <p className="hero-txt">
                We create custom framed mirrors tailored to your specific needs.
                Whether you're outfitting a new home or starting from scratch,
                we’ve got the solution. Our team will design and build a custom
                framed mirror to fit your space perfectly and manage the
                installation for a flawless finish.
              </p>
            </div>
            <div className="img-div">
              <img
                src={images4[currentSlide]}
                alt="home-hero"
                className={fading ? "fade-out" : "heroImg"}
              />
            </div>
          </div>
        </Fade>
      )}
      <div className="tab-panel">
        <div
          className={activeSlide === 1 ? "tab-active-white" : "tab"}
          onClick={() => handleSlideChange(1)}
        >
          <h6>
            <strong>Frame Plain Mirror</strong>
          </h6>
          <p className="tab-text">Update your bare mirrors</p>
        </div>
        <div
          className={activeSlide === 2 ? "tab-active-black" : "tab"}
          onClick={() => handleSlideChange(2)}
        >
          <h6>
            <strong>Transform Your Mirror</strong>
          </h6>
          <p className="tab-text">Designer look for your space</p>
        </div>
        <div
          className={activeSlide === 3 ? "tab-active-white" : "tab"}
          onClick={() => handleSlideChange(3)}
        >
          <h6>
            <strong>Custom Mirrors</strong>
          </h6>
          <p className="tab-text">Dont have any mirrors? No problem!</p>
        </div>
        <div
          className={activeSlide === 4 ? "tab-active-black" : "tab"}
          onClick={() => handleSlideChange(4)}
        >
          <h6>
            <strong>Artisanal Custom Mirrors</strong>
          </h6>
          <p className="tab-text">We build and hang new framed mirrors</p>
        </div>
      </div>
    </div>
  );
};

export default HeaderD;
