import React from "react";
import Menu from "../components/home1/Menu";
import Navbar from "../components/home1/Navbar";
import Footer from "../components/home1/Footer";
import { Helmet } from "react-helmet";
import TranformOlder from "../components/home1/transform-older-mirrors";
import Cta from "../components/home1/cta";

const TranformOlderr = () => {
  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          href="/home1/assets/css/home_1_style.css"
        />
        <script src="/common/assets/js/common_js.js"></script>
        <script src="/home1/assets/js/home_1_scripts.js"></script>
      </Helmet>
      <body className="home-style1">
        <Menu />
        <div className="smooth-scroll-content" id="scrollsmoother-container">
          <Navbar />
          <main>
            <TranformOlder />
            <Cta />
          </main>
          <Footer />
        </div>
      </body>
    </>
  );
};

export default TranformOlderr;
