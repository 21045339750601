import React from "react";
import "./headerD.css";

const Cta = () => {
  return (
    <div className="cta-main-root">
      <div className="cta-sub-root">
        <div className="cta-text-div">
          <h4 className="cta-txt">
            Trust the experts in mirror, art, and TV framing. We'll handle your
            precious items with care and create a stunning display that you'll
            love. Contact us today!
          </h4>
          <button className="butn border rounded-pill mt-20 color-[#795C32] border-[#795C32] hover-bg-[#795C32]">
            Contact Us
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cta;
