import React from "react";
import Fade from "react-reveal/Fade";
import "./headerD.css";

const BuilderGradeMirros = () => {
  return (
    <div className="plain-main-root">
      <div className="plain-sub-root">
        <div className="plain-text-div">
          <Fade left duration={1500}>
            <h1 className="pain-heading">
              Elevate the Everyday: Reimagine Builder-Grade Spaces
            </h1>
            <p className="plain-text">
              Builder-grade spaces often lack the personality and charm that
              truly make a house a home. However, with a touch of creativity and
              strategic updates, you can transform these cookie-cutter areas
              into personalized havens that reflect your unique style. Start by
              focusing on key elements like lighting, hardware, and paint. Swap
              out basic fixtures for statement pieces that add a touch of
              elegance or whimsy. Upgrade outdated hardware with sleek, modern
              options that elevate the overall aesthetic. Infuse color and
              personality through a fresh coat of paint or eye-catching
              wallpaper. Consider adding architectural details like crown
              molding or wainscoting to create visual interest and dimension.
              Don't forget about textiles! Incorporate plush rugs, luxurious
              throws, and vibrant pillows to add warmth and texture. With a
              little imagination and effort, you can breathe new life into
              builder-grade spaces, turning them into stylish and inviting
              retreats that you'll love coming home to.
            </p>
          </Fade>
        </div>
        <div className="img-div-plain">
          <Fade right duration={1500}>
            <img src="/home1/before2.jpg" alt="" className="img-plain" />
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default BuilderGradeMirros;
