import React from "react";
import Fade from "react-reveal/Fade";
import "./headerD.css";

const NeedBathMirrors = () => {
  return (
    <div className="plain-main-root">
      <div className="plain-sub-root-old">
        <div className="img-div-plain">
          <Fade left duration={1500}>
            <img src="/home1/before2.jpg" alt="" className="img-plain" />
          </Fade>
        </div>
        <div className="plain-text-div">
          <Fade right duration={1500}>
            <h1 className="pain-heading">
              Building Your Dream Home? Reflect on Our Framed Bathroom Mirrors
            </h1>
            <p className="plain-text">
              Embarking on the journey of building a new home is an exciting
              endeavor, filled with countless decisions to ensure every detail
              aligns with your vision. When it comes to the bathroom,
              functionality and aesthetics go hand-in-hand, and a well-chosen
              mirror can elevate the entire space. That's where we come in. We
              understand that bathroom mirrors are more than just reflective
              surfaces; they're essential elements that contribute to the
              overall ambiance and usability of the room. Whether you're seeking
              a sleek and modern design or a more traditional and ornate style,
              our collection of framed bathroom mirrors offers a wide range of
              options to complement your unique taste and décor. From magnifying
              mirrors for precise grooming to full-length mirrors for
              head-to-toe outfit checks, we have the perfect solution to meet
              your specific needs. Our commitment to quality craftsmanship and
              attention to detail ensures that each mirror is not only beautiful
              but also durable and long-lasting. Let us help you create a
              bathroom that reflects your personal style and enhances your daily
              routine.
            </p>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default NeedBathMirrors;
