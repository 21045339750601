import React from "react";
import Fade from "react-reveal/Fade";
import "./headerD.css";

const FrameTV = () => {
  return (
    <div className="plain-main-root">
      <div className="plain-sub-root">
        <div className="plain-text-div">
          <Fade left duration={1500}>
            <h1 className="pain-heading">
              Transform Your TV from Tech to Chic: Elevate Your Entertainment
              with Our Custom Framing
            </h1>
            <p className="plain-text">
              In today's world, where technology seamlessly blends with interior
              design, why settle for a plain black box dominating your living
              space? We believe that your TV deserves to be more than just a
              source of entertainment; it should be a stylish statement piece
              that complements your décor. Our custom TV framing service offers
              the perfect solution to elevate your viewing experience and
              transform your television into a work of art. We understand that
              every home is unique, which is why we offer a wide variety of
              frame styles, colors, and finishes to perfectly match your
              personal taste and existing décor. Whether you prefer a sleek and
              modern look or a more traditional and ornate design, our team of
              skilled craftsmen will create a custom frame that seamlessly
              integrates your TV into your living space. Say goodbye to
              unsightly wires and distracting reflections. Our expertly crafted
              frames not only enhance the aesthetic appeal of your TV but also
              provide practical benefits such as concealing cables and reducing
              glare. Let us help you transform your television from a
              technological necessity into a captivating centerpiece that
              reflects your discerning style and elevates your entertainment
              experience.
            </p>
          </Fade>
        </div>
        <div className="img-div-plain">
          <Fade right duration={1500}>
            <img src="/home1/before2.jpg" alt="" className="img-plain" />
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default FrameTV;
