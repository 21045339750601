import React from "react";
import Fade from "react-reveal/Fade";
import "./headerD.css";

const TranformOlder = () => {
  return (
    <div className="plain-main-root">
      <div className="plain-sub-root-old">
        <div className="img-div-plain">
          <Fade left duration={1500}>
            <img src="/home1/before2.jpg" alt="" className="img-plain" />
          </Fade>
        </div>
        <div className="plain-text-div">
          <Fade right duration={1500}>
            <h1 className="pain-heading">
              Transform Your Mirror, Transform Your Space
            </h1>
            <p className="plain-text">
              Transforming older mirrors is a brilliant way to revitalize not
              just the mirror itself, but the entire ambiance of your bathroom.
              These reflective surfaces, often overlooked, possess immense
              potential to elevate your space with a touch of creativity and
              minimal effort. A fresh coat of paint on the frame, a mosaic of
              colorful tiles, or even a decoupage of vintage botanical prints
              can breathe new life into a tired mirror. For a more dramatic
              change, consider repurposing an old window frame or salvaged wood
              to create a unique and rustic frame. Even a simple addition of
              decorative knobs or hooks can enhance functionality and style.
              Embracing the possibilities of mirror transformations opens up a
              world of personalized design, allowing you to infuse your bathroom
              with character and charm.
            </p>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default TranformOlder;
