import React from "react";
import Footer from "../../components/innerpage/Footer";
import Menu from "../../components/home1/Menu";
import Navbar from "../../components/home1/Navbar";
import StartButton from "../../components/home5_residence/StartButton";
import Header from "../../components/innerpage/contact/Header";
import ContactInfo from "../../components/innerpage/contact/ContactInfo";
import Map from "../../components/innerpage/contact/Map";
import ContactForm from "../../components/innerpage/contact/ContactForm";
import { Helmet } from "react-helmet";

function ContactPage() {
  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          href="/innerpages/assets/css/innerpages.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/home1/assets/css/home_1_style.css"
        />
        <script src="/common/assets/js/common_js.js"></script>
        <script src="/innerpages/assets/js/innerpages.js"></script>
        <script src="/common/assets/js/common_js.js"></script>
        <script src="/home1/assets/js/home_1_scripts.js"></script>
      </Helmet>
      <body className="inner-pages-style1 contact-pg-style1">
        <Menu />
        <div>
          <Navbar />
          <Header />
          <main>
            <ContactInfo />
            <Map />
            <ContactForm />
          </main>
          <Footer />
        </div>
        <StartButton />
      </body>
    </>
  );
}

export default ContactPage;
