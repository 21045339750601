import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home1 from "./pages/home1";
import PlainM from "./pages/frame-plain-mirrors";
import ContactPage from "./pages/innerpages/contact";
import TranformOlderr from "./pages/transform-older-mirrors";
import UpdateBuilder from "./pages/update-builder-grade";
import DontHaveMir from "./pages/dont-have-mir";
import NeedBathMir from "./pages/need-bath-mir";
import HangMir from "./pages/hang-mir";
import HangFramed from "./pages/hang-framed";
import FrameT from "./pages/frame-t";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home1 />} />
        <Route path="/frame-plain-mirrors" element={<PlainM />} />
        <Route path="/transform-older-mirrors" element={<TranformOlderr />} />
        <Route
          path="/update-builder-grade-mirrors"
          element={<UpdateBuilder />}
        />
        <Route path="/dont-have-mirror" element={<DontHaveMir />} />
        <Route path="/building-new-home" element={<NeedBathMir />} />
        <Route path="/hang-heavy-mirror" element={<HangMir />} />
        <Route path="/hang-framed-mirror" element={<HangFramed />} />
        <Route path="/frame-your-tv" element={<FrameT />} />
        <Route path="/contact" element={<ContactPage />} />
      </Routes>
    </Router>
  );
}

export default App;
