import React, { useState, useRef, useEffect } from "react";
import "./headerD.css";

function Navbar() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div style={{ background: "#000", height: "35px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            float: "right",
            padding: "0 35px",
            color: "#fff",
            height: "100%",
            gap: "15px",
          }}
        >
          <p>+1 (469)-286-6327</p>
          <p style={{ textDecoration: "underline", cursor: "pointer" }}>
            Contact Us
          </p>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg navbar-dark tc-navbar-style1 section-padding-x">
        <div className="container-fluid content">
          <a className="navbar-brand" href="/">
            <img src="/home1/logoF.png" alt="" className="logo" height="40px" />
            <img
              src="/home1/logo.png"
              alt=""
              className="logo-web"
              width="120px"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse"
            id="navbarSupportedContent"
            style={{ justifyContent: "center" }}
          >
            <ul className="navbar-nav mb-2 mb-lg-0">
              <li
                className="nav-item dropdown"
                onMouseEnter={toggleDropdown}
                onMouseLeave={() => setIsDropdownOpen(false)}
              >
                <a
                  className="nav-link"
                  aria-current="page"
                  href="#"
                  onClick={toggleDropdown}
                  style={{ height: "100%" }}
                >
                  Services
                </a>
                <ul
                  ref={dropdownRef}
                  className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}
                  aria-labelledby="navbarDropdown"
                >
                  <div
                    className="menu-white-ser"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "20px",
                    }}
                  >
                    <div>
                      <li
                        className="dropdown-header hh-d"
                        style={{ width: "300px", fontWeight: "900" }}
                      >
                        Frame Existing Mirror &gt;
                      </li>
                      <li style={{ padding: "10px !important" }}>
                        <a
                          className="dropdown-item"
                          href="/frame-plain-mirrors"
                        >
                          Frame Plain Mirrors
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/transform-older-mirrors"
                        >
                          Transform Older Mirrors
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/update-builder-grade-mirrors"
                        >
                          Update Builder Grade Mirrors
                        </a>
                      </li>
                    </div>
                    <div className="border-men" />
                    <div>
                      <li
                        className="dropdown-header hh-d"
                        style={{ width: "300px", fontWeight: "900" }}
                      >
                        Custom Mirror &gt;
                      </li>
                      <li>
                        <a className="dropdown-item" href="/dont-have-mirror">
                          Don't Have a Mirror? No Problem!
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/building-new-home">
                          Building a new home and need bathroom mirrors?
                        </a>
                      </li>
                    </div>
                    <div className="border-men" />
                    <div>
                      <li
                        className="dropdown-header hh-d"
                        style={{ width: "300px", fontWeight: "900" }}
                      >
                        Mirror Hanging &gt;
                      </li>
                      <li>
                        <a className="dropdown-item" href="/hang-heavy-mirror">
                          Hang a Heavy Mirror
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/hang-framed-mirror">
                          Hang Framed Art/Mirror
                        </a>
                      </li>
                    </div>
                    <div className="border-men" />
                    <div>
                      <li
                        className="dropdown-header hh-d"
                        style={{ width: "300px", fontWeight: "900" }}
                      >
                        TV Framing &gt;
                      </li>
                      <li>
                        <a className="dropdown-item" href="/frame-your-tv">
                          Frame Your TV's
                        </a>
                      </li>
                    </div>
                  </div>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  About
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Projects
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/contact">
                  Contact
                </a>
              </li>
            </ul>
          </div>
          <div className="nav-side mobile-hid">
            <a href="#" className="icon ms-5 fsz-21">
              <span>
                <i className="la la-search" style={{ color: "#000" }}></i>
              </span>
            </a>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
